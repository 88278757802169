import axios, { getHeaders } from '@/plugins/axios'

const listContent = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/blog/patient-app-post/', { params, headers })
    .catch((err) => err.response)
}

const getContent = ({ commit }, { slug }) => {
  const headers = getHeaders()
  return axios
    .get(`/blog/patient-app-post/${slug}/`, { headers })
    .then((response) => {
      const education = response.data
      commit('SET_EDUCATION', {education})
      return response
    })
    .catch((err) => err.response)
}

const createContent = ({ commit }, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/blog/patient-app-post/', data, { headers })
    .then((response) => {
      commit('SET_EDUCATION', { education: response.data })
      return response
    })
    .catch((err) => err.response)
}

const updateContent = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .patch(`/blog/patient-app-post/${data.slug}/`, data, { headers })
    .catch((err) => err.response)
}

const updateImage = (_, { data, slug }) => {
  console.log(data)
  const headers = { ...getHeaders(), 'Content-Type': 'multipart/form-data' }
  return axios
    .patch(`/blog/patient-app-post/${slug}/`, data, { headers })
    .catch((err) => err.response)
}

const getCategories = (_) => {
  const headers = getHeaders()
  return axios
    .get('/blog/patient-app-post-category/', { headers })
    .catch((err) => err.response)
}

const createCategory = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/blog/patient-app-post-category/', data, { headers })
    .catch((err) => err.response)
}

const resetContent = ({ commit }) => {
  commit('RESET_EDUCATION')
}


export default {
  listContent,
  getContent,
  createContent,
  updateContent,
  updateImage,
  getCategories,
  createCategory,
  resetContent,
}
