const mutations = {
  SET_BLOG_TOPIC(state, { blogTopic }) {
    state.newBlogTopic = blogTopic
  },
  RESET_BLOG_TOPIC(state) {
    state.newBlogTopic = {
      name: '',
      description: '',
      groups: [],
      tags: [],
      is_active: true,
      cover_image: null,
      color: '',
      icon: ''
    }
  },
  SET_BLOG_ENTRY(state, { blogEntry }) {
    state.newBlogEntry = blogEntry
  },
  RESET_BLOG_ENTRY(state) {
    state.newBlogEntry = {
      title: '',
      content: '',
      desing: '',
      published_at: '',
      is_active: true,
      topic: null,
      roles: []
    }
  },
  SET_NEW_BLOG_ENTRY_IMG(state, { newImage }) {
    state.newBlogEntryImg = newImage
  },
}

export default mutations
