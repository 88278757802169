import Vue from 'vue'
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify/lib/framework'
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import es from 'vuetify/lib/locale/es'
import light from './theme'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
    values: {
      dropdown: 'expand_more',
    },
  },
  theme: {
    themes: { light },
    options: { customProperties: true },
  },
})
