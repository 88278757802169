import axios, { getHeaders } from '@/plugins/axios'

const listBlogTopic = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/blog/patient-app-blog-topic/', { params, headers })
    .catch((err) => err.response)
}

const listAllBlogTopics = (_) => {
  const headers = getHeaders()
  return axios
    .get('/blog/patient-app-blog-topic/list_all/', { headers })
    .catch((err) => err.response)
}

const listBlogPost = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/blog/patient-app-blog-entry/', { params, headers })
    .catch((err) => err.response)
}

const getBlogTopic = ({ commit }, { id }) => {
  const headers = getHeaders()
  return axios
    .get(`/blog/patient-app-blog-topic/${id}/`, { headers })
    .then((response) => {
      const blogTopic = response.data
      commit('SET_BLOG_TOPIC', { blogTopic })
      return response
    })
    .catch((err) => err.response)
}

const getBlogEntry = ({ commit }, { id }) => {
  const headers = getHeaders()
  return axios
    .get(`/blog/patient-app-blog-entry/${id}/`, { headers })
    .then((response) => {
      const blogEntry = response.data
      commit('SET_BLOG_ENTRY', { blogEntry })
      return response
    })
    .catch((err) => err.response)
}

const createBlogTopic = ({ commit }, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/blog/patient-app-blog-topic/', data, { headers })
    .then((response) => {
      const blogTopic = response.data
      commit('SET_BLOG_TOPIC', { blogTopic })
      return response
    })
    .catch((err) => err.response)
}

const createBlogEntry = ({ commit }, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/blog/patient-app-blog-entry/', data, { headers })
    .then((response) => {
      const blogEntry = response.data
      commit('SET_BLOG_ENTRY', { blogEntry })
      return response
    })
    .catch((err) => err.response)
}

const updateBlogTopic = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .patch(`/blog/patient-app-blog-topic/${data.id}/`, data, { headers })
    .catch((err) => err.response)
}

const updateBlogEntry = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .patch(`/blog/patient-app-blog-entry/${data.id}/`, data, { headers })
    .catch((err) => err.response)
}

const updateImage = (_, { data, id }) => {
  console.log(data)
  const headers = { ...getHeaders(), 'Content-Type': 'multipart/form-data' }
  return axios
    .patch(`/blog/patient-app-blog-entry/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const resetBlogTopic = ({ commit }) => {
  commit('RESET_BLOG_TOPIC')
}

const resetBlogEntry = ({ commit }) => {
  commit('RESET_BLOG_ENTRY')
}

const setBlogTopic = ({ commit }, { topic }) => {
  const blogTopic = {
    ...topic,
    tags: topic.tags.map((t) => t.id),
    groups: topic.groups.map((g) => g.id),
  }
  commit('SET_BLOG_TOPIC', { blogTopic })
}

const setNewBlogEntryImg = ({ commit }, { newImage }) => {
  commit('SET_NEW_BLOG_ENTRY_IMG', { newImage })
}


export default {
  listBlogTopic,
  listAllBlogTopics,
  listBlogPost,
  getBlogTopic,
  getBlogEntry,
  createBlogTopic,
  createBlogEntry,
  updateBlogTopic,
  updateBlogEntry,
  updateImage,
  resetBlogTopic,
  resetBlogEntry,
  setBlogTopic,
  setNewBlogEntryImg
}
