const mutations = {
  SET_EDUCATION(state, { education }) {
    state.newEducation = education
  },
  RESET_EDUCATION(state) {
    state.newEducation = {
      title: '',
      groups: [],
      categories: [],
      published_at: null,
      is_active: true,
      cover_image: null
    }
  }
}

export default mutations
