import axios, { getHeaders } from '@/plugins/axios'

const listSurvey = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/surveys/', { params, headers })
    .catch((err) => err.response)
}

const getSurvey = ({ commit }, { id }) => {
  const headers = getHeaders()
  return axios
    .get(`/surveys/${id}/`, { headers })
    .then((response) => {
      const survey = response.data
      commit('SET_SURVEY', {survey})
      return response
    })
    .catch((err) => err.response)
}

const createSurvey = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/surveys/', data, { headers })
    .catch((err) => err.response)
}

const updateSurvey = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .put(`/surveys/${data.id}/`, data, { headers })
    .catch((err) => err.response)
}

const downloadAnswersCSV = (_, surveyID) => {
  const headers = getHeaders()
  return axios
    .get(`/surveys/${surveyID}/export_answers_csv/`, { headers })
    .catch((err) => err.response)
}

const getRoles = (_) => {
  const headers = getHeaders()
  return axios
    .get('/auth/roles/portal_clientes_roles/', { headers })
    .catch((err) => err.response)
}

const resetSurvey = ({ commit }) => {
  commit('RESET_SURVEY')
}


export default {
  listSurvey,
  getSurvey,
  createSurvey,
  updateSurvey,
  downloadAnswersCSV,
  getRoles,
  resetSurvey
}
