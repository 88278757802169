import axios, { getHeaders } from '@/plugins/axios'

export default {
  listClientEvents (_, { params } = {}) {
    const headers = getHeaders()
    return axios
      .get('/client/client_event/', { params, headers })
      .catch((err) => err.response)
  },
  getEventTypes () {
    const headers = getHeaders()
    return axios
      .get('/client/client_event/event-types/', { headers })
        .then((response) => ({ data: response.data, response: response }))
        .catch((error) => ({ error }))

  }
}

