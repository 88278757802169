export default {
  SET_ASSISTED_SEARCH(state, { data }) {
    state.assistedSearch = data
  },
  SET_FROM_ASSITED_SEARCH(state, { data }) {
    state.fromAssitedSearch = data
  },
  SET_MANUAL_SEARCH(state, { data }) {
    state.manualSearch = data
  },
  PUSH_PACKAGE(state, { data }) {
    state.paquetes.push(data)
  },
  DELETE_PACKAGE(state, { id }) {
    state.paquetes = state.paquetes.filter((paquete) => paquete.package !== id)
  },
  RESET_PACKAGE(state) {
    state.paquetes = []
  },
  SET_PACKAGE_SUGGESTIONS(state, { data }) {
    state.paquetesSugeridos = data
  },
  RESET_PACKAGE_SUGGESTIONS(state) {
    state.paquetesSugeridos = []
  }
}
