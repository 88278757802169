import axios, { getHeaders } from '@/plugins/axios'

export default {
  listLandingPage (_, { params }) {
    const headers = getHeaders()
    return axios
      .get('/landing/landing-page/', { params, headers })
      .catch((err) => err.response)
  },

  getLandingPage (_, { id, params }) {
    const headers = getHeaders()
    return axios
      .get(`/landing/landing-page/${id}/`, { params, headers })
      .catch((err) => err.response)
  },

  createLandingPage (_, { data }) {
    const headers = getHeaders()
    return axios
      .post('/landing/landing-page/', data, { headers })
      .catch((err) => err.response)
  },

  updateLandingPage (_, { id, data }) {
    const headers = getHeaders()
    return axios
      .patch(`/landing/landing-page/${id}/`, data, { headers })
      .catch((err) => err.response)
  },

  deleteLandingPage (_, { id }) {
    const headers = getHeaders()
    return axios
      .delete(`/landing/landing-page/${id}/`, { headers })
      .catch((err) => err.response)
  },

  listLandingPageResult (_, { params }) {
    const headers = getHeaders()
    return axios
      .get('/landing/landing-page-result/', { params, headers })
      .catch((err) => err.response)
  },

  getLandingPageResult (_, { id, params }) {
    const headers = getHeaders()
    return axios
      .get(`/landing/landing-page-result/${id}/`, { params, headers })
      .catch((err) => err.response)
  },

  listLandingPageResultEvent (_, { id, params }) {
    const headers = getHeaders()
    return axios
      .get(`/landing/landing-page-result/${id}/events_count/`, { params, headers })
      .catch((err) => err.response)
  },

  listLandingPageResultFormSubmissions (_, { id, params }) {
    const headers = getHeaders()
    return axios
      .get(`/landing/landing-page-result/${id}/form_submissions/`, { params, headers })
      .catch((err) => err.response)
  },

  

  exportLandingPageResultFormSubmissions (_, { id, params }) {
    const headers = getHeaders()
    return axios({
      method: 'get',
      url: `/landing/landing-page-result/${id}/export_excel_form_submissions/`,
      responseType: 'blob',
      headers
    })
    .then ((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'reporte.xlsx');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return response
    })
    .catch((err) => err.response)
  },

  exportLandingPageResultEvents (_, { id }) {
    const headers = getHeaders()
    return axios({
      method: 'get',
      url: `/landing/landing-page-result/${id}/export_excel_events/`,
      responseType: 'blob',
      headers
    })
    .then ((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'reporte.xlsx');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return response
    })
    .catch((err) => err.response)
  },
}