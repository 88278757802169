import * as getters from './getters'
import mutations from './mutations';
import actions from './actions'

const bannerStore = {
  namespaced: true,
  state:{},
  getters,
  mutations,
  actions,
}

export default bannerStore
