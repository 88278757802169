import axios, { getHeaders } from '@/plugins/axios'

const listClient = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/client/client/', { params, headers })
    .catch((err) => err.response)
}

const getClient = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/client/client/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const createClient = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/client/client/', data, { headers })
    .catch((err) => err.response)
}

const updateClient = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/client/client/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const deleteTagClient = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/operation/client-tag/${id}/`, headers)
    .catch((err) => err.response)
}

const addTagClient = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post(`/operation/client-tag/`, data, { headers })
    .catch((err) => err.response)
}

const unBanClient = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .get(`/client/client/${id}/unban_from_forum/`, { headers })
    .catch((err) => err.response)
}

const banClient = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .get(`/client/client/${id}/ban_from_forum/`, { headers })
    .catch((err) => err.response)
}

export default { listClient, getClient, createClient, updateClient, deleteTagClient, addTagClient, unBanClient, banClient }
