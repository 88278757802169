import axios, { getHeaders } from '@/plugins/axios'

const listBanners = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/banners/patient-app-banner/', { params, headers })
    .catch((err) => err.response)
}

const getBanner = ({ commit }, { id }) => {
  const headers = getHeaders()
  return axios
    .get(`/banners/patient-app-banner/${id}/`, { headers })
    .then((response) => {
      const banner = response.data
      commit('SET_BANNER', {banner})
      return response
    })
    .catch((err) => err.response)
}

const createBanner = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/banners/patient-app-banner/', data, { headers })
    .catch((err) => err.response)
}

const updateBanner = (_, { data, id }) => {
  const headers = getHeaders()
  return axios
    .put(`/banners/patient-app-banner/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const deleteBanner = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/banners/patient-app-banner/${id}/`, { headers })
    .catch((err) => err.response)
}

const partialUpdateBanner = (_, { data, id }) => {
  const headers = getHeaders()
  return axios
    .patch(`/banners/patient-app-banner/${id}/`, data, { headers })
    .catch((err) => err.response)
}

export default {
  listBanners,
  getBanner,
  createBanner,
  updateBanner,
  deleteBanner,
  partialUpdateBanner
}
