import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const AuthenticationStore = {
  namespaced: true,
  state: {
    user: null,
  },
  getters,
  mutations,
  actions,
};

export default AuthenticationStore;
