import axios, { getHeaders } from '@/plugins/axios'

const listAgreement = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/operation/agreement/', { params, headers })
    .catch((err) => err.response)
}

export default { listAgreement }
