import axios, { getHeaders } from '@/plugins/axios'

const setAssistedSearch = ({ commit }, data) => {
  commit('SET_ASSISTED_SEARCH', { data })
}

const setFromAssistedSearch = ({ commit }, data) => {
  commit('SET_FROM_ASSITED_SEARCH', { data })
}

const setManualSearch = ({ commit }, data) => {
  commit('SET_MANUAL_SEARCH', { data })
}

const listPackages = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/packages/packages/', { params, headers })
    .catch((err) => err.response)
}

const fetchPackageSuggestions = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/packages/fetch_package_suggestions/', data, headers)
    .catch((err) => err.response)
}

const pushPackage = ({ commit }, selectedPackage) => {
  const defaultItemsIDs = selectedPackage.default_items.map(item => item.service.id)
  const data = {
    isCreated: false,
    package: selectedPackage.id,
    codeName: `${selectedPackage.code} - ${selectedPackage.name}`,
    price: selectedPackage.price,
    servicesInArea: selectedPackage.item_sets.map((item) => {
      return {
        ...item,
        validatedTotal: true,
        totalService: item.services.filter(service => defaultItemsIDs.includes(service.id)).map(service => {
          return {
            daily_amount: parseFloat(selectedPackage.default_items.find(i => i.service.id === service.id).daily_amount),
            number_of_days: selectedPackage.default_items.find(i => i.service.id === service.id).number_of_days,
            }
        }),
        serviciosAutocomplete: item.services.filter(service => service.area_servicio === item.service_area.id).filter(service => !defaultItemsIDs.includes(service.id)),
        servicios: item.services.filter(service => defaultItemsIDs.includes(service.id)).map(service => {
          return {
            id: service.id,
            name: service.name,
            code: service.code,
            daily_amount: parseFloat(selectedPackage.default_items.find(i => i.service.id === service.id).daily_amount),
            number_of_days: selectedPackage.default_items.find(i => i.service.id === service.id).number_of_days,
            }
          })
      }
    }),
  }
  commit('PUSH_PACKAGE', { data })
}
const pushPackageCreated = ({ commit }, selectedPackage) => {
  const defaultItemsIDs = selectedPackage.servicios.map(item => item.service)
  const data = {
    isCreated: false,
    package: selectedPackage.id,
    codeName: `${selectedPackage.code} - ${selectedPackage.name}`,
    price: selectedPackage.price,
    servicesInArea: selectedPackage.item_sets.map((item) => {
      return {
        ...item,
        validatedTotal: true,
        serviciosAutocomplete: item.services.filter(service => service.area_servicio === item.service_area.id).filter(service => !defaultItemsIDs.includes(service.id)),
        servicios: item.services.filter(service => defaultItemsIDs.includes(service.id)).map(service => {
          return {
            id: service.id,
            name: service.name,
            code: service.code,
            daily_amount: parseFloat(selectedPackage.servicios.find(i => i.service === service.id).daily_amount),
            number_of_days: selectedPackage.servicios.find(i => i.service === service.id).number_of_days,
            }
          })
      }
    }),
  }
  commit('PUSH_PACKAGE', { data })
}

const deletePackage = ({ commit }, { id }) => {
  commit('DELETE_PACKAGE', { id })
}

const resetPackage = ({ commit }) => {
  commit('RESET_PACKAGE')
}

const setSuggestedPackages = ({ commit }, { data }) => {
  commit('SET_PACKAGE_SUGGESTIONS', { data })
}

const resetSuggestedPackages = ({ commit }) => {
  commit('RESET_PACKAGE_SUGGESTIONS')
}

export default { setAssistedSearch, setFromAssistedSearch, setManualSearch, listPackages, fetchPackageSuggestions,  pushPackage, pushPackageCreated, deletePackage, resetPackage, setSuggestedPackages, resetSuggestedPackages }
