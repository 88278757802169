import axios, { getHeaders } from '@/plugins/axios'

const listEnfermero = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/operation/enfermero/', { params, headers })
    .catch((err) => err.response)
}

export default { listEnfermero }
