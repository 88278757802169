const mutations = {
  SET_USER(state, { user }) {
    localStorage.clear();
    if (user) {
      state.user = user;
    } else {
      state.user = null;
    }
    localStorage.setItem('user', JSON.stringify(user));
  },
};

export default mutations;
