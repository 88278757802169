import axios from 'axios'
import settings from '@/../settings'

/**
 * Genera un Request Header con el token de autorización.
 * @returns Objeto HEADERS para pasarle a Axios
 */
const getHeaders = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const key = user?.key
  return key ? { Authorization: `Token ${key}` } : {}
}

const instance = axios.create({
  baseURL: `${settings.baseAPIUrl}/api/`,
  headers: getHeaders(),
})

export default instance
export { getHeaders }
