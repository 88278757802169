import axios, { getHeaders } from '@/plugins/axios'

const listTag = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/operation/tag/', { params, headers })
    .catch((err) => err.response)
}

const getTag = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/tag/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const listManualTags = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/tag/list_manual`, { params, headers })
    .catch((err) => err.response)
}

const listAllTags = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/tag/list_all`, { headers })
    .catch((err) => err.response)
}

const createTag = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/operation/tag/', data, headers)
    .catch((err) => err.response)
}

const updateTag = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/operation/tag/${id}/`, data, headers)
    .catch((err) => err.response)
}

const deleteTag = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/operation/tag/${id}/`, headers)
    .catch((err) => err.response)
}

export default { listTag, getTag, createTag, updateTag, deleteTag, listManualTags, listAllTags }
