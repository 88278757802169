import * as getters from './getters'
import mutations from './mutations';
import actions from './actions'

const forumStore = {
  namespaced: true,
  state:{
    newBlogTopic: {
      name: '',
      description: '',
      groups: [],
      tags: [],
      is_active: true,
      cover_image: null,
      color: '',
      icon: ''
    },
    newBlogEntry: {
      title: '',
      content: '',
      desing: '',
      published_at: '',
      is_active: true,
      topic: null,
      roles: []
    },
    newBlogEntryImg: null
  },
  getters,
  mutations,
  actions,
}

export default forumStore
