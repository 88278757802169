import axios, { getHeaders } from '@/plugins/axios'

const listEmailTemplate= (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/mailing/mail-template/', { params, headers })
    .catch((err) => err.response)
}

const getEmailTemplate = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/mailing/mail-template/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const createEmailTemplate = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/mailing/mail-template/', data, { headers })
    .catch((err) => err.response)
}

const updateEmailTemplate = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .patch(`/mailing/mail-template/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const deleteEmailTemplate = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/mailing/mail-template/${id}/`, { headers })
    .catch((err) => err.response)
}

const listEmailTemplateCategories = (_) => {
  const headers = getHeaders()  
  return axios
    .get('/mailing/mail-template/categories/', { headers })
    .catch((err) => err.response)
}

const uploadEmailTemplateImage = (_, { data }) => {
  const headers = getHeaders()
  headers['Content-Type'] = 'multipart/form-data'
  return axios
    .post('/mailing/mail-image/', data, { headers })
    .catch((err) => err.response)
}

const listEmailCampaign= (_, { params }={}) => {
  const headers = getHeaders()
  return axios
    .get('/mailing/mail-campaign/', { params, headers })
    .catch((err) => err.response)
}

const getEmailCampaign = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/mailing/mail-campaign/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const createEmailCampaign = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/mailing/mail-campaign/', data, { headers })
    .catch((err) => err.response)
}

const updateEmailCampaign = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .patch(`/mailing/mail-campaign/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const deleteEmailCampaign = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/mailing/mail-campaign/${id}/`, { headers })
    .catch((err) => err.response)
}

const sendTestEmailCampaign = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .post(`/mailing/mail-campaign/${id}/send_test/`, data, { headers })
    .catch((err) => err.response)
}

const sendEmailCampaign = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .post(`/mailing/mail-campaign/${id}/send/`, {}, { headers })
    .catch((err) => err.response)
}

const listEmailCampaignResult= (_, { params }={}) => {
  const headers = getHeaders()
  return axios
    .get('/mailing/mail-campaign-result/', { params, headers })
    .catch((err) => err.response)
}

const getEmailCampaignResult = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/mailing/mail-campaign-result/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const getEmailCampaignResultTracking = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/mailing/mail-campaign-result/${id}/email_tracking/`, { params, headers })
    .catch((err) => err.response)
}

// exporta los email a excel
const exportEmailCampaignResult= (_, { id }) => {
  const headers = {
    ...getHeaders(),
  }
  return axios({
      method: 'get',
      url: `/mailing/mail-campaign-result/${id}/export_excel/`,
      responseType: 'blob',
      headers
    })
    .then ((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'reporte.xlsx');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return response
    })
    .catch((err) => err.response)
}

export default {
  exportEmailCampaignResult,
  getEmailCampaignResultTracking,
  getEmailCampaignResult,
  listEmailCampaignResult,
  sendEmailCampaign,
  sendTestEmailCampaign,
  listEmailCampaign,
  updateEmailCampaign,
  deleteEmailCampaign,
  getEmailCampaign,
  createEmailCampaign,
  uploadEmailTemplateImage,
  listEmailTemplate,
  getEmailTemplate,
  createEmailTemplate,
  updateEmailTemplate,
  listEmailTemplateCategories,
  deleteEmailTemplate
}
