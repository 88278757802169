import * as getters from './getters';
import mutations from './mutations'
import actions from './actions'

const packagesStore = {
  namespaced: true,
  state: {
    assistedSearch: false,
    fromAssitedSearch: false,
    manualSearch: true,
    paquetes: [],
    paquetesSugeridos: []
  },
  getters,
  mutations,
  actions,
}

export default packagesStore
