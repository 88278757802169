import axios, { getHeaders } from '@/plugins/axios'

const listRegion = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/operation/region/', { params, headers })
    .catch((err) => err.response)
}

export default { listRegion }
