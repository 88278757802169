// Componente vacío con un router-view de paso
const PassThru = { render: (c) => c('router-view') }

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        redirect: { name: 'listaPresupuesto' },
        component: () => import('@/views/Dashboard.vue'),
        meta: {
          breadcrumb: 'Dashboard',
          dashboardIcon: 'home.png',
          isNavItem: true,
          navText: 'Dashboard',
        },
      },
      /* --------------------------------- Pedidos B2C / Presupuestos --------------------------------- */
      {
        path: 'presupuesto',
        component: PassThru,
        meta: {
          breadcrumb: 'Presupuesto',
        },
        children: [
          {
            path: '',
            name: 'listaPresupuesto',
            component: () => import('@/views/ListPedidosB2C.vue'),
            meta: {
              breadcrumb: 'Lista Presupuesto',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
              dashboardIcon: 'budget-list.png',
              isNavItem: true,
              navText: 'Presupuestos',
            },
          },
          {
            path: 'nuevo',
            name: 'nuevoPresupuesto',
            component: () => import('@/views/NewPedidoB2C.vue'),
            meta: {
              breadcrumb: 'Nuevo Presupuesto',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
          {
            path: ':id',
            name: 'editarPresupuesto',
            component: () => import('@/views/NewPedidoB2C.vue'),
            meta: {
              breadcrumb: 'Editar Presupuesto',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
        ],
      },
      /* ----------------------------------------- Pedidos B2B ---------------------------------------- */
      {
        path: 'b2b',
        component: PassThru,
        meta: {
          breadcrumb: 'B2B',
        },
        children: [
          {
            path: '',
            name: 'listaPedidosB2B',
            component: () => import('@/views/ListPedidosB2B.vue'),
            meta: {
              breadcrumb: 'Bandeja de Cotizaciones',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
              dashboardIcon: 'inbox.png',
              isNavItem: true,
              navText: 'Cotizaciones',
            },
          },
          {
            path: 'nuevo',
            name: 'nuevoPedidoB2B',
            component: () => import('@/views/NewPedidoB2B.vue'),
            meta: {
              breadcrumb: 'Nuevo Pedido',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
          {
            path: ':id',
            name: 'editarPedidoB2B',
            component: () => import('@/views/NewPedidoB2B.vue'),
            meta: {
              breadcrumb: 'Editar Pedido',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
        ],
      },
      /* ------------------------------------------- Cliente ------------------------------------------ */
      {
        path: 'cliente',
        component: PassThru,
        meta: {
          breadcrumb: 'Cliente',
        },
        children: [
          {
            path: '',
            name: 'listaCliente',
            component: () => import('@/views/ListCliente.vue'),
            meta: {
              breadcrumb: 'Lista Cliente',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
              dashboardIcon: 'client.png',
              isNavItem: true,
              navText: 'Clientes',
            },
          },
          {
            path: 'nuevo',
            name: 'nuevoCliente',
            component: () => import('@/views/NewCliente.vue'),
            meta: {
              breadcrumb: 'Nuevo Cliente',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
          {
            path: ':id',
            name: 'editarCliente',
            component: () => import('@/views/NewCliente.vue'),
            meta: {
              breadcrumb: 'Editar Cliente',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
        ],
      },
      /* ------------------------------------------- CRM ------------------------------------------ */
      {
        path: 'crm',
        component: PassThru,
        name: 'crm',
        redirect: { name: 'listaEtiqueta' },
        meta: {
          dashboardIcon: 'budget-new.png',
          breadcrumb: 'CRM',
          isNavItem: true,
          isSubMenu: false,
          isMenu: true,
          navText: 'CRM',
        },
        children: [
          /* -------------------------------------- CRM/Etiquetas ------------------------------------- */
          {
            path: 'lista-etiquetas',
            name: 'listaEtiqueta',
            component: () => import('@/views/ListEtiqueta.vue'),
            meta: {
              breadcrumb: 'Etiquetas',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
              isNavItem: true,
              navText: 'Etiquetas',
              isSubMenu: true,
            },
          },
          {
            path: 'nuevo',
            name: 'nuevaEtiqueta',
            component: () => import('@/views/NewEtiqueta.vue'),
            meta: {
              breadcrumb: 'Nueva Etiqueta',
              isSubMenu: false,
              isNavItem: false,
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
          /* -------------------------------------- CRM/Grupos ------------------------------------- */
          {
            path: 'grupos',
            name: 'listaGrupos',
            component: () => import('@/views/ListGrupos.vue'),
            meta: {
              breadcrumb: 'Grupos',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
              isNavItem: true,
              navText: 'Grupos',
              isSubMenu: true,
            },
          },
          {
            path: 'grupos/nuevo',
            name: 'nuevoGrupo',
            component: () => import('@/views/NewGrupo.vue'),
            meta: {
              breadcrumb: 'Nuevo Grupo',
              isSubMenu: false,
              isNavItem: false,
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
          {
            path: 'grupos/:id',
            name: 'editarGrupo',
            component: () => import('@/views/NewGrupo.vue'),
            meta: {
              breadcrumb: 'Editar Grupo',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial',
                'Enfermero',
              ],
            },
          },
          {
            path: 'grupos/:groupId/journey',
            name: 'editarJourney',
            component: () => import('@/views/Journey.vue'),
            meta: {
              breadcrumb: 'Editar Journey',
              allowedGroups: ['Gerente', 'Supervisor', 'Enfermero comercial'],
            },
          },
          {
            component: PassThru,
            name: 'plantillas-email',
            redirect: { name: 'listaPlantillasEmail' },
            path: 'plantillas-email',
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Plantillas Mail',
              navText: 'Plantillas',
              allowedGroups: ['Gerente', 'Supervisor', 'Enfermero comercial'],
            },
            children: [
              {
                path: '',
                name: 'listaPlantillasEmail',
                component: () => import('@/views/ListPlantillasEmail.vue'),
                breadcrumb: 'Plantillas Mail',
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              },
              {
                path: 'editar/:id',
                name: 'EditPlantillaEmail',
                component: () => import('@/views/EditPlantillaEmail.vue'),
                meta: {
                  breadcrumb: 'Editar Plantillas Mail',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              },
            ],
          },
          {
            component: PassThru,
            name: 'camp-email',
            redirect: { name: 'ListCampañasEmail' },
            path: 'camp-email',
            meta: {
              isNavItem: true,
              isSubMenu: true,
              navText: 'Campañas Mail',
              breadcrumb: 'Campañas Mail',
              allowedGroups: ['Gerente', 'Supervisor', 'Enfermero comercial'],
            },
            children: [
              {
                path: '',
                name: 'ListCampañasEmail',
                component: () => import('@/views/ListCampañasEmail.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              },
              {
                path: 'editar/:id',
                name: 'EditCampañaEmail',
                component: () => import('@/views/EditCampañaEmail.vue'),
                meta: {
                  breadcrumb: 'Creador de Campañas',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              }
            ]
          },
          {
            component: PassThru,
            name: 'resultados-capm-email',
            redirect: { name: 'ListResultadosCampañasEmail' },
            path: 'resultados-capm-email',
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Reportes Mail',
              navText: 'Reportes Mail',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListResultadosCampañasEmail',
                component: () => import('@/views/ListResultadosCampañasEmail.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: ':id',
                name: 'VerResultadosCampañasEmail',
                component: () => import('@/views/VerResultadosCampañasEmail.vue'),
                meta: {
                  breadcrumb: 'Reportes Mail',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
            ]
          },
          {
            component: PassThru,
            name: 'landings',
            redirect: { name: 'ListLandingPage' },
            path: 'landings',
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Landings',
              navText: 'Landings',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListLandingPage',
                component: () => import('@/views/ListLandingPage.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: ':id',
                name: 'EditLandingPage',
                component: () => import('@/views/EditLandingPage.vue'),
                meta: {
                  breadcrumb: 'Creador de Landings',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: ':id/plantilla',
                name: 'EditLandingPagePlantilla',
                component: () => import('@/views/EditLandingPagePlantilla.vue'),
                meta: {
                  breadcrumb: 'Editar Plantilla',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                }
              }
            ]
          },
          {
            component: PassThru,
            name: 'landings-resultados',
            redirect: { name: 'ListLandingPageResult' },
            path: 'landings-resultados',
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Reportes Landings',
              navText: 'Reportes Landings',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListLandingPageResult',
                component: () => import('@/views/ListLandingPageResult.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: ':id',
                name: 'VerResultadosLandings',
                component: () => import('@/views/VerResultadosLandings.vue'),
                meta: {
                  breadcrumb: 'Reportes Landings',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              }
            ]
          },
        ],
      },
      {
        path: 'educacion',
        component: PassThru,
        name: 'educacion',
        redirect: { name: 'listaContenidoEducativo' },
        meta: {
          dashboardIcon: 'school.png',
          breadcrumb: 'Contenido',
          isNavItem: true,
          isSubMenu: false,
          isMenu: true,
          navText: 'Contenido',
        },
        children: [
          {
            component: PassThru,
            path: 'contenido',
            name: 'listaContenidoEducativo',
            redirect: { name: 'ListEducation' },
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Educativo',
              navText: 'Educativo',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListEducation',
                component: () => import('@/views/ListEducation.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: 'nuevo',
                name: 'nuevoContenido',
                component: () => import('@/views/NewEducation.vue'),
                meta: {
                  breadcrumb: 'Crear Contenido',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                    'Enfermero',
                  ],
                },
              },
              {
                path: ':slug',
                name: 'editarContenido',
                component: () => import('@/views/NewEducation.vue'),
                meta: {
                  breadcrumb: 'Editar Contenido',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                    'Enfermero',
                  ],
                },
              },
              {
                path: ':slug/nuevo-contenido',
                name: 'crearContenidoEducativo',
                component: () => import('@/views/NewEducationContent.vue'),
                meta: {
                  breadcrumb: 'Crear Contenido',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                    'Enfermero',
                  ],
                },
              },
            ]
          },
          {
            component: PassThru,
            path: 'encuestas',
            name: 'surveys',
            redirect: { name: 'ListSurveys' },
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Encuestas',
              navText: 'Encuestas',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListSurveys',
                component: () => import('@/views/ListSurveys.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: 'nuevo',
                name: 'nuevaEncuesta',
                component: () => import('@/views/NewSurvey.vue'),
                meta: {
                  breadcrumb: 'Nueva Encuesta',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                    'Enfermero',
                  ],
                },
              },
              {
                path: ':id',
                name: 'editarEncuesta',
                component: () => import('@/views/NewSurvey.vue'),
                meta: {
                  breadcrumb: 'Editar Encuesta',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                    'Enfermero',
                  ],
                },
              },
              {
                path: ':id',
                name: 'verEncuesta',
                component: () => import('@/views/NewSurvey.vue'),
                meta: {
                  breadcrumb: 'Ver Encuesta',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                    'Enfermero',
                  ],
                },
              },
            ]
          },
          {
            component: PassThru,
            path: 'temas-foro',
            name: 'blogTopic',
            redirect: { name: 'ListBlogTopics' },
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Categorías',
              navText: 'Temas Foro',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListBlogTopics',
                component: () => import('@/views/ListBlogTopics.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              }
            ]
          },
          {
            component: PassThru,
            path: 'entradas-foro',
            name: 'blogPost',
            redirect: { name: 'ListBlogPosts' },
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Administrador de Entradas del Foro',
              navText: 'Entradas Foro',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListBlogPosts',
                component: () => import('@/views/ListBlogPosts.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: 'nuevo',
                name: 'nuevaEntradaBlog',
                component: () => import('@/views/NewBlogPost.vue'),
                meta: {
                  breadcrumb: 'Crear Entradas del Foro',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              },
              {
                path: ':id',
                name: 'editarEntradaBlog',
                component: () => import('@/views/NewBlogPost.vue'),
                meta: {
                  breadcrumb: 'Editar Entradas del Foro',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              }
            ]
          },
          {
            component: PassThru,
            path: 'banners',
            name: 'banners',
            redirect: { name: 'ListBanners' },
            meta: {
              isNavItem: true,
              isSubMenu: true,
              breadcrumb: 'Administrador de Banners',
              navText: 'Banners',
              allowedGroups: [
                'Gerente',
                'Supervisor',
                'Enfermero comercial'
              ],
            },
            children: [
              {
                path: '',
                name: 'ListBanners',
                component: () => import('@/views/ListBanners.vue'),
                meta: {
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial'
                  ],
                },
              },
              {
                path: 'nuevo',
                name: 'nuevoBanner',
                component: () => import('@/views/NewBanner.vue'),
                meta: {
                  breadcrumb: 'Crear Banner',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              },
              {
                path: ':id',
                name: 'editarBanner',
                component: () => import('@/views/EditBanner.vue'),
                meta: {
                  breadcrumb: 'Editar Banner',
                  allowedGroups: [
                    'Gerente',
                    'Supervisor',
                    'Enfermero comercial',
                  ],
                },
              }
            ]
          },
        ]
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/recover-password',
    name: 'recuperarContraseña',
    component: () => import('@/views/RecoverPasswordSendEmail.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/recover-password-form/:uid/:token',
    name: 'recuperarContraseñaForm',
    component: () => import('@/views/RecoverPasswordForm.vue'),
    meta: {
      isPublic: true,
    },
  },

  // 404
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: '404',
        component: () => import('@/views/ErrorNotFound.vue'),
      },
    ],
  },
]

export default routes
