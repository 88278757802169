const mutations = {
  SET_SURVEY(state, { survey }) {
    state.newSurvey = survey
  },
  RESET_SURVEY(state) {
    state.newSurvey = {
      name: '',
      target_roles: [],
      published_at: null,
      finished_at: null,
      periodicity: '',
      periodicity_value: 0,
      target_clinic_groups: [],
      active: true,
      questions: [{
        question_text: '',
        order: 0,
        alternatives: [
          {
            alternative_text: '',
            order: 0
          },
          {
            alternative_text: '',
            order: 1
          }
        ]
      }]
    }
  }
}

export default mutations
