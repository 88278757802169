import * as getters from './getters'
import mutations from './mutations';
import actions from './actions'

const surveyStore = {
  namespaced: true,
  state:{
    newSurvey: {
      name: '',
      description: '',
      target_roles: [],
      published_at: null,
      finished_at: null,
      periodicity: '',
      periodicity_value: 0,
      target_clinic_groups: [],
      active: true,
      questions: [{
        question_text: '',
        order: 0,
        alternatives: [
          {
            alternative_text: '',
            order: 0
          },
          {
            alternative_text: '',
            order: 1
          }
        ]
      }]
    }
  },
  getters,
  mutations,
  actions,
}

export default surveyStore
