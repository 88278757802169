export const newSurvey = (state) => state.newSurvey
export const newSurveyValid = (state) => {
  return {
    name: state.newSurvey.name !== '' && state.newSurvey.name !== null,
    targetRoles: state.newSurvey.target_roles.length > 0,
    targetClinicGroups: state.newSurvey.target_clinic_groups.length > 0,
    questionsCount: state.newSurvey.questions.length > 0,
    questionsText: state.newSurvey.questions.every(
      (q) => q.question_text !== '' && q.question_text !== null
    ),
    questionsAlternativesCount: state.newSurvey.questions.every(
      (q) => q.alternatives.length >= 2
    ),
    alternativesText: state.newSurvey.questions.every(
      (q) => q.alternatives.every((a) =>  a.alternative_text !== '' && a.alternative_text !== null)
    )
  }
}
