import * as getters from './getters'
import mutations from './mutations';
import actions from './actions'

const educationStore = {
  namespaced: true,
  state:{
    newEducation: {
      title: '',
      groups: [],
      categories: [],
      published_at: null,
      is_active: true,
      cover_image: null
    }
  },
  getters,
  mutations,
  actions,
}

export default educationStore
