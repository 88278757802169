import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const mailingStore = {
  namespaced: true,
  state: {
  },
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions,
}

export default mailingStore
