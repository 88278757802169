import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Fragment from 'vue-fragment'
import VueBreadcrumbs from 'vue-breadcrumbs'
import VuetifyToast from 'vuetify-toast-snackbar'
import '@babel/polyfill'
import VueMoment from 'vue-moment'
import 'moment/locale/es'

Vue.config.productionTip = false
Vue.use(Fragment.Plugin)
Vue.use(VueBreadcrumbs, { registerComponent: false })
Vue.use(require('vue-moment'))
Vue.use(VuetifyToast)
if (!Vue.moment) {
  Vue.use(VueMoment)
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
