<template lang="pug">
v-app
  router-view
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  created () {
    this.checkAuth()
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    checkAuth() {
      const { isPublic, allowedGroups } = this.$route.meta
      const { name } = this.$route
      const hasPerm =
        allowedGroups && this.user
          ? allowedGroups.some((r) => this.user.groups.indexOf(r) >= 0)
          : true
      if (isPublic) {
        if (this.user && name === 'login') {
          /** Vista Login y usuario autenticado: Se redirecciona al Dashboard */
          this.$router.push({ name: 'dashboard' }).catch(() => {})
        }
      } else if (!this.user) {
        /** Vista privada y usuario NO autenticado: Se redirecciona al Login */
        this.$router.push({ name: 'login' }).catch(() => {})
      } else if (!hasPerm) {
        this.$router.push({ name: 'dashboard' }).catch(() => {})
      }
    },
    restoreUserFromLocalStorage() {
      const storedUser = JSON.parse(window.localStorage.getItem('user'))
      if (storedUser && storedUser.email) {
        this.$store.commit('AuthenticationStore/SET_USER', { user: storedUser })
      }
    },
  },
  created() {
    this.restoreUserFromLocalStorage()
  },
  watch: {
    async user() {
      this.checkAuth()
    },
    $route() {
      this.checkAuth()
    },
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@/styles/global.scss';
@import '~vuetify/src/styles/settings/_variables';

html {
  font-size: 16px !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 14px !important;
  }
}
</style>
