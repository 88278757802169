export default {
  primary: {
    base: '#2c549b',
    lighten1: '#6280cc',
    darken1: '#002c6c',
  },

  secondary: {
    base: '#32a936',
    lighten1: '#6bdc65',
    darken1: '#007900',
  },

  // botones
  button: '#1867c0',

  accent: '#82B1FF', // por defecto
  error: '#FB5050',
  info: '#2196F3', // por defecto
  success: '#4CAF50', // por defecto
  warning: '#FB8C00', // por defecto
  muted: '#999999', // por defecto
  tertiary: '#04D5C7'
}
