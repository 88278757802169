import axios, { getHeaders } from '@/plugins/axios'

const listGroup = (_, { params }) => {
  const headers = getHeaders()
  return axios
    .get('/operation/group/', { params, headers })
    .catch((err) => err.response)
}

const getGroup = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/group/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const createGroup = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/operation/group/', data, { headers })
    .catch((err) => err.response)
}

const updateGroup = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/operation/group/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const deleteGroup = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/operation/group/${id}/`, headers)
    .catch((err) => err.response)
}

const listAllGroups = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/group/list_all`, { headers })
    .catch((err) => err.response)
}

const getJourney = (_, { groupId }) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/group/${groupId}/journey/`, { headers })
    .catch((err) => err.response)
}

const updateJourney = (_, { groupId, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/operation/group/${groupId}/journey/`, data, { headers })
    .catch((err) => err.response)
}

const updateJourneyActive = (_, { groupId, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/operation/group/${groupId}/active_joueny/`, data, { headers })
    .catch((err) => err.response)
}

export default { listGroup, getGroup, createGroup, updateGroup, deleteGroup, listAllGroups, getJourney, updateJourney, updateJourneyActive }
