import axios, { getHeaders } from '@/plugins/axios'

const listService = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/operation/service/', { params, headers })
    .catch((err) => err.response)
}

const getService = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/operation/service/${id}/`, { params, headers })
    .catch((err) => err.response)
}

// const createService = (_, { data }) => {
//   const headers = getHeaders()
//   return axios
//     .post('/operation/service/', data, headers)
//     .catch((err) => err.response)
// }

// const updateService = (_, { id, data }) => {
//   const headers = getHeaders()
//   return axios
//     .put(`/operation/service/${id}/`, data, headers)
//     .catch((err) => err.response)
// }

// export default { listService, getService, createService, updateService }
export default { listService, getService }
