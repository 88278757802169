import axios, { getHeaders } from '@/plugins/axios'

export const login = ({ commit }, { email, password }) => {
  if (email && password) {
    return axios
      .post('/operation/admin/login', {
        username: email,
        email,
        password,
      })
      .then(
        (response) => {
          const user = response.data
          commit('SET_USER', { user })
          return response
        },
        (error) => {
          console.error(error)
          return error.response
        }
      )
  }
}

export const logout = ({ commit }) => {
  commit('SET_USER', { user: null })
}

export const sendEmailRecoverPassword = (_, { email }) => {
  return axios
    .post('/operation/admin/send-email-recover-password', { email })
    .then(
      (response) => {
        return response
      },
      (error) =>  { 
        console.error(error)
        return error.response
      }
    )
}

export const getRoles = (_) => {
  const headers = getHeaders()
  return axios
    .get('/auth/roles/portal_clientes_roles/', { headers })
    .catch((err) => err.response)
}

export const changePassword = (_, data) => {
  return axios
    .post('/rest-auth/password/reset/confirm/', data)
    .then(
      (response) => {
        return response
      },
      (error) =>  { 
        console.error(error)
        return error.response
      }
    )
}
