import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'

const groupStore = {
  namespaced: true,
  state: {
    formNew: {
      name: null,
      description: null,
      included: [],
      excluded: [],
      color: '#bdc3c7'
    }
  },
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    updateField,
  },
  actions,
}

export default groupStore
