export const newEducation = (state) => state.newEducation
export const newEducationValid = (state) => {
  return {
    title: state.newEducation.title !== '' && state.newEducation.title !== null,
    published_at: state.newEducation.published_at !== '' && state.newEducation.published_at !== null,
    categories: state.newEducation.categories.length > 0,
    groups: state.newEducation.groups.length > 0,
    roles: state.newEducation.roles?.length > 0,
  }
}
